<template>
  <div class="box">
    <div class="testBox">
      <p class="p1">
        <span class="span span1"></span>
        <span class="span span2"></span>
        <span class="span span3"></span>
        <span class="p"> 此产品暂不支持 </span>
        <span class="span span4"></span>
        <span class="span span5"></span>
        <span class="span span6"></span>
      </p>
      <p class="p2">规划制图此功能</p>
      <p class="p3">{{ num }}秒后将返回 PM POWER</p>
    </div>
    <div class="btnbox">
      <div class="button" @click="backPmPower">返回 PM POWER</div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      num: 5,
      timer: null,
      iframeWin: {},
    };
  },
  created() {
  
  },
  mounted() {
    this.num = 5
    this.timer = setInterval(this.fun, 1000);
  },
  methods: {
    backPmPower() {
       this.num = 5
        clearInterval(this.timer);
        this.timer=null
      this.$router.push('/home')
    },
    fun() {
      this.num--;
      if (this.num === 0) {
          this.$router.push('/home')
        clearInterval(this.timer);
        this.timer=null
      }
    },
  },
};
</script>
<style scoped lang="scss">
* {
  margin: 0;
  padding: 0;
}
.box {
  width: 100%;
  height: calc(100vh - 98px);
  background-color: #fff;
}
.testBox {
  text-align: center;
  padding-top: 350px;
  .p1 {
    .p {
      font-family: PangMenZhengDao;
      color: rgba(0, 0, 0, 0.7);
      font-size: 40px;
      font-weight: 600;
    }
    .span {
      display: inline-block;
      width: 6px;
      height: 6px;
      background-color: #000000;
      margin-right: 3px;
      border-radius: 50%;
      margin-bottom: 10px;
    }
    .span1,
    .span6 {
      background-color: #d1d1d1;
    }
    .span2,
    .span5 {
      background-color: #989898;
    }
    .span3,
    .span4 {
      background-color: #666666;
    }
  }
  .p2 {
    font-family: PangMenZhengDao;
    color: rgba(0, 0, 0, 0.7);
    font-size: 40px;
    font-weight: 600;
  }
  .p3 {
    font-family: PangMenZhengDao;
    // color: rgba(0, 0, 0, 0.7);
    font-size: 40px;
    font-weight: 600;
    line-height: 100px;
  }
}
.btnbox {
  padding: 40px;
  padding-top: 70%;
  .button {
    width: 670px;
    height: 85px;
    background: #ec6602;
    border-radius: 43px;
    font-size: 28px;
    font-weight: bold;
    color: #ffffff;
    line-height: 85px;
    text-align: center;
  }
}
</style>